<template>
  <div class="accrual-approval">
    <template v-if="statusId === 1">
      <button
        class="accrual-approval__button"
        @click="handleRequest(true)"
      >
        Принять
      </button>

      <button
        class="accrual-approval__button"
        @click="handleRequest(false)"
      >
        Отклонить
      </button>
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import accruals from '@/api/accruals'

export default defineComponent({
  name: 'AccuralApproval',
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async handleRequest (isAprooved) {
      try {
        await accruals.handleAccrual(
          this.requestId,
          isAprooved,
        )
        this.$emit('resolve-request')
      } catch (error) {
        console.warn(error)
      }
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/accruals-table/styles/typography.scss';

.accrual-approval {
  display: flex;
  gap: 16px;
  &__button {
    width: max-content;
    height: 32px;
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .3s all ease-in-out;
    background-color: $primary-100;
    color: $primary-400;
    &:hover {
      background-color: $primary-200;
    }
  }
}
</style>
