<template>
  <div class="accrual-date">
    {{ date }}
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccrualDate',
  props: {
    datetime: {
      type: String,
      default: '',
    },
  },
  computed: {
    date() {
      return new Date(this.datetime).toLocaleString()
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/accruals-table/styles/typography.scss';

.accrual-date {
  color: $dark-400;
  @include base-text;
}
</style>
