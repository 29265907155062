<template>
  <div>
    <div :class="$style.withdrawalMoney">
      <Title text="Вывод денег" position="left" />

      <Tabs
        style="margin-bottom: 1.5rem"
        :tabs="tabs"
        :default-active-tab-id="activeTab"
        @input="(id) => (activeTab = id)"
      />

      <accruals-table v-if="activeTab === 1"/>

      <div v-if="activeTab === 2" :class="$style.withdrawalMoney__header">
        <date-picker
          v-model="date"
          range
          :lang="lang"
          placeholder="Дата"
          :editable="false"
          :formatter="momentFormat"
          @input="getReportOnWithdrawalMoney(1)"
          @change="clearData"
        />
      </div>

      <div v-if="activeTab === 2" :class="$style.withdrawalMoney__counter">
        <h3>Количество строк: {{ withdrawalMoneyCount }}</h3>

        <Button
          :class="$style.withdrawalMoney__header__button"
          type="tertiary"
          center
          :disabled="withdrawalMoneyCount === 0 || loading"
          @click="getReportOnWithdrawalMoneyFile"
        >
          Скачать xlsx файл
        </Button>
      </div>
      <div v-if="activeTab === 2" :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <img
                v-if="headerColumn.value"
                src="@/assets/icons/arrow.svg"
                alt="arrow"
                @click="sortWithdrawals(headerColumn.value)"
              />
            </div>
          </div>
        </div>
        <div :class="$style.table__content">
          <div
            v-for="withdrawal in totalItems"
            :key="withdrawal.id"
            :class="[
              $style.table__content_row,
              withdrawal.status === 'Закрытая' && $style.table__content_row_active
            ]"
          >
            <div :class="$style.table__content_column">
              {{ withdrawal.id }}
            </div>
            <div :class="$style.table__content_column">
              {{ moment(withdrawal.createdAt).format('DD-MM-YYYY  LT') }}
            </div>
            <div :class="$style.table__content_column">
              {{ withdrawal.status.name }}
            </div>
            <div :class="$style.table__content_column">
              {{ withdrawal.user.attributes.firstName }}
              {{ withdrawal.user.attributes.lastName }}
            </div>
            <div :class="$style.table__content_column">
              {{ withdrawal.sum }}
            </div>
            <div :class="$style.table__content_column">
              <Button
                v-if="withdrawal.statusId == '1'"
                type="table"
                @click="approve(withdrawal.id)"
              >
                Одобрить
              </Button>
              <Button
                v-if="withdrawal.statusId == '1'"
                type="table"
                @click="reject(withdrawal.id)"
              >
                Отклонить
              </Button>
            </div>
          </div>
          <div :class="$style.table__content_wrapperLine">
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
          </div>
        </div>
      </div>
    </div>
    <pagination
      v-if="activeTab === 2"
      :pageCount="pageCount"
      :prevText="''"
      :click-handler="getReportOnWithdrawalMoney"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      :initial-page="this.$route.query.page - 1"
    />
  </div>
</template>

<script>
import withdrawals from '@/api/withdrawals'

import Moment from 'moment'
import Title from '@/basic/Title'
import Tabs from '@/basic/Tabs'
import Button from '@/basic/Button'
import DatePicker from 'vue2-datepicker'
import { AccrualsTable } from '@/widgets/accruals-table'
import ru from 'vue2-datepicker/locale/ru'
import paginationMixin from '@/mixins/pagination.mixin'

import 'vue2-datepicker/index.css'

const headerColumns = [
  {
    id: 1,
    value: 'id',
    title: 'id'
  },
  {
    id: 2,
    value: '',
    title: 'Дата'
  },
  {
    id: 3,
    value: '',
    title: 'Статус'
  },
  {
    id: 4,
    value: '',
    title: 'ФИО'
  },
  {
    id: 5,
    value: '',
    title: 'Сумма'
  },
  {
    id: 6,
    value: '',
    title: 'Одобрение'
  }
]
export default {
  name: 'WithdrawalMoney',
  components: {
    Title,
    Tabs,
    DatePicker,
    Button,
    AccrualsTable
  },
  mixins: [paginationMixin],
  data() {
    return {
      lang: ru,
      withdrawals: [],
      withdrawalMoneyCount: 0,
      loading: false,
      sortMethod: 'desc',
      date: [],
      headerColumns,
      momentFormat: {
        stringify: (date) => {
          return date ? this.moment(date).format('DD-MM-YYYY') : ''
        }
      },
      tabs: [
        {
          id: 1,
          name: 'Вывод средств',
        },
        {
          id: 2,
          name: 'Вывод средств (архив)',
        },
      ],
      activeTab: 1,
    }
  },
  async mounted() {
    const page = parseInt(this.$route.query.page)
    const { data } = await withdrawals.getWithdrawals({
      filter: {
        createdAt: {
          start: this.startDate,
          end: this.endDate
        }
      },
      pagination: {
        offset: page * 10 - 10,
        limit: 10
      },
      order: {
        field: 'id',
        by: this.sortMethod
      }
    })
    this.withdrawalMoneyCount = data.result.count
    this.withdrawals = data.result.rows
    this.setupWithdrawalMoneyPagination(this.withdrawals, data.result.count)
  },
  computed: {
    moment() {
      return Moment
    },
    startDate() {
      return this.date.length && this.moment(this.date[0]).format('YYYY-MM-DD')
    },
    endDate() {
      return this.date.length && this.moment(this.date[1]).format('YYYY-MM-DD')
    }
  },
  methods: {
    async getReportOnWithdrawalMoney(num) {
      try {
        const page = +num ?? parseInt(this.$route.query.page)

        const { data } = await withdrawals.getWithdrawals({
          filter: {
            createdAt: {
              start: this.startDate,
              end: this.endDate
            }
          },
          pagination: {
            offset: page * 10 - 10,
            limit: 10
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        })

        if (data?.success && !data?.result?.count) {
          this.withdrawalMoneyCount = data.result.count
          this.withdrawals = []
          this.setupWithdrawalMoneyPagination(this.withdrawals, data.result.count)
          return this.openNotice(
            'InfoNotice',
            `По данному периоду (${this.moment(this.startDate).format(
              'DD-MM-YYYY'
            )} - ${this.moment(this.endDate).format(
              'DD-MM-YYYY'
            )}) вывода средств не найдено.`,
            2500
          )
        }

        if (data?.success) {
          this.withdrawalMoneyCount = data.result.count
          this.withdrawals = data.result.rows

          this.setupWithdrawalMoneyPagination(this.withdrawals, data.result.count)
          this.$router.push(`?page=${page}`).catch(() => {})
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения данных с сервера!`, 2500)
      }
    },
    async sortWithdrawals() {
      this.toggleSortMethod()
      const page = this.$route.query.page ? parseInt(this.$route.query.page) : 1

      try {
        const { data } = await withdrawals.getWithdrawals({
          filter: {
            createdAt: {
              start: this.startDate,
              end: this.endDate
            }
          },
          pagination: {
            offset: page * 10 - 10,
            limit: 10
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        })

        if (data?.success) {
          this.withdrawalMoneyCount = data.result.count
          this.withdrawals = data.result.rows

          this.setupWithdrawalMoneyPagination(this.withdrawals, data.result.count)
          this.$router.push(`?=page${page}`).catch(() => {})
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения данных с сервера!`, 2500)
      }
    },
    async getReportOnWithdrawalMoneyFile() {
      try {
        this.loading = true

        const resp = await withdrawals.getReportOnWithdrawalMoneyFile({
          filter: {
            createdAt: {
              start: this.startDate,
              end: this.endDate
            }
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        })

        if (resp?.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(resp.data)
          link.download = `Отчет по выводу средств с ${this.moment(this.startDate).format(
            'DD-MM-YYYY'
          )} по ${this.moment(this.endDate).format('DD-MM-YYYY')}.xlsx`
          link.click()
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения данных с сервера!`, 2500)
      } finally {
        this.loading = false
      }
    },
    approve(id) {
      const res = withdrawals.approveWithdrawals(id)
      console.log(res)
    },
    reject(id) {
      const res = withdrawals.rejectWithdrawals(id)
      console.log(res)
    },
    setCurrentPage(pageNumber) {
      this.currentPage = pageNumber
    },
    clearData() {
      this.withdrawalMoneyCount = 0
      this.withdrawals = []
      this.$router.push('/withdrawalMoney').catch(() => {})
    },
    openNotice(type, text, timer) {
      this.$store.commit(
        'setNoticeData',
        `<p style="margin: 2rem 3rem; font-size: 14px">${text}</p>`
      )

      this.$store.commit('setNotice', type)

      setTimeout(() => {
        this.$store.commit('setNoticeData', null)
        this.$store.commit('setNotice', null)
      }, timer)
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.withdrawalMoney {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    &_input {
      width: 15rem;
      margin: 0 4rem 0 0;
    }
  }
  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 1rem;
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 7.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }

      &_wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
