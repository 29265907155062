<template>
  <div
    class="accrual-status"
    :class="[statusColorClass]"
  >
    <div class="accrual-status__marker" />

    <p class="accrual-status__text">
      {{ statusName }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

const ACRUAL_STATUS = [
  {
    id: 1,
    name: 'новый',
  },
  {
    id: 2,
    name: 'принят',
  },
  {
    id: 3,
    name: 'отклонен',
  }
]

export default defineComponent({
  name: 'AccrualStatus',
  props: {
    statusId: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    statusName () {
      return ACRUAL_STATUS.find((accrualStatus) => {
        return accrualStatus.id === this.statusId
      }).name
    },
    statusColorClass () {
      const statusColors = {
        'новый': 'accrual-status--new',
        'принят': 'accrual-status--accepted',
        'отклонен': 'accrual-status--rejected'
      }

      return statusColors[this.statusName]
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/accruals-table/styles/typography.scss';

.accrual-status {
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
  padding: 4px;
  border-radius: 4px;
  &__marker {
    flex-shrink: 0;
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  &__text {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
  &#{&}--accepted {
    background-color: $success-100;
  }
  &#{&}--new {
    background-color: $primary-100;
  }
  &#{&}--rejected {
    background-color: $danger-100;
  }
  &--accepted {
    .accrual-status {
      &__marker {
        background-color: $success-400;
      }
      &__text {
        color: $success-600;
      }
    }
  }
  &--new {
    .accrual-status {
      &__marker {
        background-color: $primary-400;
      }
      &__text {
        color: $primary-800;
      }
    }
  }
  &--rejected {
    .accrual-status {
      &__marker {
        background-color: $danger-400;
      }
      &__text {
        color: $danger-600;
      }
    }
  }
}
</style>
